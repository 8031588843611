import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { isEmail, isURL } from "validator";
import validator from "validator";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import swal from "sweetalert2";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import "./PopupModal.css";
import FormLoader from "./FormLoader";
import {
  createNewCompany,
  createNewCompanyEndPoint,
  searchCompany,
  searchCompanyEndPoint,
} from "../api/AssignIndustry";
import { ListCountriesEndPoint } from "../api/ListCountries";
import { UpdateTaxDataEndpoint } from "../api/UpdateTaxData";
import { SuccessHandler } from "./alerts/SuccessAlert";
import { ErrorHandler } from "./alerts/ErrorAlert";
import { FormErrorHandler } from "./alerts/FormError";
import { ConfirmErrorHandler } from "./alerts/ConfirmHandler";
import { FormDisabledError } from "./alerts/FormDisabled";

const PopupModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [form, formData] = useState({});
  const [dropDown, setDropDown] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState();
  const [formLoader, setFormLoader] = useState(true);
  const [hideFields, setHideFields] = useState(true);
  const [formDisabled, setFormDisabled] = useState(true);
  // this is state to store the values of tax transid
  const [TaxTransID, setTaxTransID] = useState("");

  // storing error message //////////////////////////////////
  const [errorMessage, setErrorMessage] = useState(null);
  // storing of search api response
  const [searchData, setSearchData] = useState([]);
  // data from the list countries api
  const [countriesData, setCountriesData] = useState([]);
  // for storing selected company after search
  const [selectedCompany, setSelectedCompany] = useState(null);
  // state for selected data
  // state for the input fields from the form
  const [searchTerm, setSearchTerm] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [website, setWebsite] = useState("");
  const [industryList, setIndustryList] = useState("");
  const [countryList, setCountry] = useState("");
  const [nameError, setNameError] = useState("");
  const [checbox, setCheckbox] = useState("");

  //  hard codede select from list option
  const hardCodedOption = {
    CountryCodeN3: "",
    CountryName: "-- SELECT FROM LIST --",
  };
  const searchHandler = async (e) => {
    setSearchTerm(e.target.value);

    // const res = await axios.get(
    //   "https://api.theproducthub.co/" + { searchTerm }
    // );
  };
  const companyHandler = (e) => {
    setCompanyName(e.target.value);
  };
  const numberHandler = (e) => {
    setNumber(e.target.value);
  };
  const emailHandler = (e) => {
    setEmail(e.target.value);
  };
  const websiteHandler = (e) => {
    setWebsite(e.target.value);
  };
  const industryListHandler = (e) => {
    setIndustryList(e.target.value);
  };
  const countryListHandler = (e) => {
    setCountry(e.target.value);
  };
  const checkBoxHandler = (e) => {
    setSelectedCompany(e.target.value);
  };
  // this functions handles the the form info modal
  const formDisabledHandler = (e) => {
    FormDisabledError();
  };
  //// confirm button below the search bar
  const confirmClickHandler = async (e) => {
    e.preventDefault();
    // if (selectedCompany.length < 0) {
    //   alert("field is required");
    //   return null;
    // }

    // alert("selected company is " + selectedCompany);
    // alert("the id is " + props.selectedIds);
    const TaxTransID = props.selectedIds[0];
    const ParentCompanyID = selectedCompany;
    const postData = { TaxTransID, ParentCompanyID };
    const res = await UpdateTaxDataEndpoint(TaxTransID, ParentCompanyID).catch(
      (err) => {
        ConfirmErrorHandler();
        console.log(err);
      }
    );
    if (res.status === 200) {
      setShow(false);
      SuccessHandler();
      setTimeout(() => {
        window.location.reload();
      }, 3000); // wait 3 seconds before reloading
    } else if (res.status !== 200) {
      return null;
    }
    console.log(res.status);

    // alert(
    //   `You are about to assign a record with a trans id of ${TaxTransID} to a parent company with the id of ${selectedCompany}`
    // );
    console.log("response status");

    // let companyID = selectedCompany?.CompanyID;
  };
  // this is the function form submission adding a new company //////

  const searchFormHandler = async (e) => {
    e.preventDefault();
    // if (searchTerm.length === 2) {
    //   setErrorMessage("Please provide more than two characters");
    //   return;
    // }

    const res = await searchCompanyEndPoint(searchTerm).then((response) => {
      const data = response.data;
      const result = data.map((item) => {
        item.parentCompanyID = item.ParentCompanyID;
        item.companyName = item.CompanyName;
        item.entityGLN = item.EntityGLN;

        return item;
      });

      if (data.length > 0) {
        setFormDisabled(false);
        setErrorMessage("");
      }

      if (data.length === 0) {
        setErrorMessage("No results were found for the company");
        setFormDisabled(false);
      }
      console.log("this is final data " + result);
      setSearchData(result);
      console.log("this is the status code" + res.status);
    });
    setSearchTerm("");

    // console.log("response from search api" + res.data);
  };

  const addNewCompanySubmitHandler = async (e) => {
    e.preventDefault();
    let errors = {};
    // if (companyName.length < 0) {
    //   alert("name is required field");
    //   return;
    // }
    const email1 = email;
    const isValidWebsite = isURL(website);
    const isValidEmail = isEmail(email1);
    // check if email is valid
    if (email.length > 0) {
      if (!isValidEmail) {
        setEmailError("invalid email address");
        return;
      } else {
        setEmailError("");
      }
    } else {
      setEmailError("");
    }
    if (website.length > 0) {
      if (!isValidWebsite) {
        setWebsiteError("please enter a valid website url");
        return;
      } else {
        setWebsiteError("");
      }
    } else {
      setWebsiteError("");
    }

    setNameError(errors.name || "");
    const res = await createNewCompanyEndPoint(
      companyName,
      number,
      email,
      website,
      countryList,
      industryList
    ).catch((err) => {
      FormErrorHandler();
      console.log(err);
    });
    const companyData = {
      companyName: companyName,
      phone: number,
      email: email,
      website: website,
      industry: industryList,
      country: countryList,
    };

    console.log("the company id is " + res.data.ParentCompanyID);
    const parentCompanyID = res.data.ParentCompanyID;
    const TaxTransID = props.selectedIds[0];
    const parentCompanyId = res.data.ParentCompanyID;
    const res1 = await UpdateTaxDataEndpoint(TaxTransID, parentCompanyId).catch(
      (err) => {
        ErrorHandler();
        console.log(err);
      }
    );
    console.log("response after creating is " + res1);
    //
    if (res1.status === 200) {
      setShow(false);

      setCompanyName("");
      setWebsiteError("");
      setEmailError("");
      setNumber("");
      setEmail("");
      setWebsite("");
      setIndustryList("");
      setCountry("");
      SuccessHandler();
      setTimeout(() => {
        window.location.reload();
      }, 3000); //
    } else {
      ErrorHandler();
    }

    console.log("company data" + JSON.stringify(companyData));
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    // implemented two way binding to clear form after submission

    const createCompany = await createNewCompanyEndPoint(
      companyName,
      number,
      email,
      website,
      industryList,
      countryList
    );
    const companyData = {
      companyName: companyName,
      phone: number,
      email: email,
      website: website,
      industry: industryList,
      country: countryList,
    };
    // the following state is to enable the form fields reset after submission using two way data binding
    setCompanyName("");
    setNumber("");
    setEmail("");
    setWebsite("");
    setIndustryList("");
    setCountry("");

    console.log("company data" + JSON.stringify(companyData));
  };

  const handleClose = () => {
    setShow(false);
    //THIS IS TO RESET FIELDS IF THE CLOSE BUTTON IS HIT ON THE MODAL

    setEmailError("");
    setWebsiteError("");
    setCompanyName("");
    setNumber("");
    setEmail("");
    setWebsite("");
    setIndustryList("");
    setCountry("");
    setSearchTerm("");
    setSearchData([]);
    setFormDisabled(true);
    setSearchTerm("");
    setErrorMessage("");
  };
  useImperativeHandle(ref, () => {
    return { show: handleShow };
  });
  const handleShow = () => {
    setShow(props.showModal);
  };

  // list countries endpoint

  useEffect(() => {
    const listCountries = async () => {
      await ListCountriesEndPoint()
        .then((response) => {
          let data = response.data;
          let result = data.map((item) => {
            item.countryCode = item.CountryCode;
            item.countryNameDisplay = item.CountryNameDisplay;
            item.countryName = item.CountryName;
            return item;
          });
          setCountriesData(result);
        })
        .catch((error) => {
          if (error.response) {
            console.log("this is an error message" + error.response.data);
            console.log(
              "this is an error message for the status" + error.response.status
            );
          }
        });
    };
    const dropDownList = async () => {
      await axios
        .get(
          "https://api.theproducthub.co/systemselect/listselection?SystemCode=LIST&SelectionCode=INDUSTRY"
        )
        .then((response) => {
          let data = response.data;
          let newData = data.map((item) => {
            item.selectionName = item.SelectionName;
            item.systemSelectID = item.SystemSelectID;
            // setDropDown(newData)
            return item;
          });
          setDropDown(newData);
          console.log("response from the select endpoint " + newData);
        });
    };
    dropDownList();
    listCountries();
  }, []);

  return (
    <>
      {/* <Button onClick={() => handleShow()} variant="primary">
        Launch static backdrop modal
      </Button> */}
      <div className="box">
        <Modal
          size="lg"
          id="modal"
          className="modal"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <div id="modal-content">
            {/* <div className="Modal_title">
              <p>
                You are about to alter the record for
                <span style={{ color: " #249df3", marginLeft: "5px" }}>
                  {props.selectedIds.length}
                </span>
                company
              </p>
            </div> */}

            <Modal.Header closeButton>
              <Modal.Title
                style={{ marginLeft: "220px", alignItems: "center" }}
              >
                Assign a parent company
              </Modal.Title>
            </Modal.Header>

            <Modal.Body className="show-grid">
              <Container>
                <Row>
                  <Col className="column" xs={6}>
                    <form onSubmit={searchFormHandler}>
                      <div className="form-group search_bar">
                        <label
                          style={{ fontSize: "14px", marginLeft: "12px" }}
                          htmlFor="search"
                          className="form-label"
                        >
                          Search For a Parent Company
                        </label>
                        <div className="search-group">
                          <input
                            id="search__box"
                            required
                            className="form-control search__box"
                            name="search"
                            placeholder="Company Name"
                            value={searchTerm}
                            onChange={searchHandler}
                          />
                          <div className="search-button">
                            <button className="button1 " type="submit">
                              Search
                            </button>
                          </div>
                        </div>
                        {/* error message */}
                        <div
                          style={{
                            color: "red",
                            marginLeft: "13px",
                            fontSize: "7px",
                          }}
                        >
                          {errorMessage}
                        </div>
                      </div>
                    </form>
                    <form>
                      {/* this is a form under the search button */}
                      <div>
                        <div
                          style={{
                            "overflow-y": "scroll",
                            height: "240px",
                            border: "1px solid #eee",
                            borderRadius: "5px",
                          }}
                        >
                          {searchData?.map((item, index) => {
                            return (
                              <div className="search-checkbox">
                                <input
                                  disabled={formDisabled}
                                  class="search-checkbox-input"
                                  type="checkbox"
                                  onChange={checkBoxHandler}
                                  checked={selectedCompany?.includes(
                                    item.ParentCompanyID
                                  )}
                                  value={item.ParentCompanyID}

                                  // checked={item.companyID}
                                />
                                <span>{item.companyName}</span>
                              </div>
                            );
                          })}
                        </div>
                        <div className="confirm">
                          <button
                            className="button1"
                            onClick={confirmClickHandler}
                            disabled={formDisabled}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </form>
                  </Col>

                  <Col className="column" xs={6}>
                    <div onClick={formDisabled ? formDisabledHandler : ""}>
                      {" "}
                      <form onSubmit={addNewCompanySubmitHandler}>
                        <label
                          className="form_label"
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            marginTop: "7px",
                          }}
                        >
                          Add New Company
                        </label>
                        <div className="form-group">
                          <input
                            className="form-control"
                            disabled={formDisabled}
                            name="companyName"
                            onChange={companyHandler}
                            placeholder="Name"
                            required="true"
                            // disabled="false"
                            value={companyName}
                          />
                          {companyName.length < 0 ? (
                            <div>{errorMessage}</div>
                          ) : (
                            ""
                          )}

                          {/* <div style={{ color: "red", marginLeft: "13px" }}>
                  {errorMessage}
                </div> */}
                        </div>
                        <div className="form-group">
                          <input
                            className="form-control"
                            disabled={formDisabled}
                            name="Phone"
                            type="text"
                            onChange={numberHandler}
                            value={number}
                            placeholder="Phone"
                          />
                        </div>
                        {emailError ? (
                          <span style={{ color: "red", fontSize: "8px" }}>
                            {emailError}
                          </span>
                        ) : (
                          ""
                        )}
                        <div className="form-group">
                          <input
                            disabled={formDisabled}
                            className="form-control"
                            name="Email"
                            type="email"
                            onChange={emailHandler}
                            placeholder="Email"
                            value={email}
                          />
                        </div>

                        <div className="form-group">
                          {websiteError ? (
                            <span style={{ color: "red", fontSize: "8px" }}>
                              {websiteError}
                            </span>
                          ) : (
                            ""
                          )}
                          <input
                            disabled={formDisabled}
                            className="form-control"
                            name="website"
                            onChange={websiteHandler}
                            placeholder="Website"
                            value={website}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="occupation" className="form-label">
                            Industry
                          </label>
                          <select
                            disabled={formDisabled}
                            className="form-select"
                            required
                            name=""
                            onChange={industryListHandler}
                            value={industryList}
                          >
                            {dropDown.map((item) => {
                              return (
                                <option value={item.systemSelectID}>
                                  {item.selectionName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="country" className="form-label">
                            Country
                          </label>
                          <select
                            required
                            disabled={formDisabled}
                            className="form-select"
                            name="occupation"
                            onChange={countryListHandler}
                            value={countryList}
                          >
                            {countriesData.map((item) => {
                              return (
                                <option value={item.CountryCodeN3}>
                                  {item.countryNameDisplay}
                                </option>
                              );
                            })}
                            {/* <option>Australia</option>
                  <option>Kenya</option>
                  <option>Other</option> */}
                          </select>
                        </div>
                        <div className="add_new">
                          <div className="form-group ">
                            <button
                              className="button1"
                              type="submit"
                              disabled={formDisabled}
                              onClick={addNewCompanySubmitHandler}
                            >
                              Add New company
                            </button>
                            <h1>{props.params}</h1>
                          </div>
                        </div>
                      </form>
                      <div className={formDisabled ? "disabled-form" : ""}>
                        {/* <div className="backdrop">bk</div> */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
              <div className=""></div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              {/* <Button variant="primary">save</Button> */}
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </>
  );
});

export default PopupModal;

import React, { useState, useRef } from "react";
import styles from "./Collapsible.module.css";

const Collapsible = (props) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };
  const contentRef = useRef();
  if (contentRef.current) console.log(contentRef.current.scrollHeight);

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.button} onClick={toggle}>
          <span className={styles.button_content}>{props.label}</span>
          <div className={styles.button_content}>
            {open ? (
              <i class="bx bx-minus-circle"></i>
            ) : (
              <i class="bx bx-plus"></i>
            )}
          </div>
        </div>
      </div>
      <div className={open ? styles.content_show : styles.content_parent}>
        <div className={styles.content}> {props.children} </div>
      </div>
    </div>
  );
};

export default Collapsible;

import swal from "sweetalert2";

const ErrorHandler = () => {
  swal.fire({
    title: "ERROR !",
    text: "There was an error when assigning the company try again ",
    icon: "error",
    confirmButtonColor: "#oD6EFD",
  });
};

export { ErrorHandler };

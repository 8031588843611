import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { render } from "react-dom";
import axios from "axios";
import ReactCountryFlag from "react-country-flag";
import CountryFilter from "../components/CountryFilter";
import "ag-grid-enterprise";
import InnerRender from "../components/InnerRender";
import Loader from "../components/Loader";
import FlagComponent from "../components/FlagComponent";
import { AgGridReact } from "ag-grid-react";
import "./DataScreen.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import FloatingFilter from "../components/FloatingFilter";
import PopupModal from "../components/PopupModal";
// import Filter from "../components/Filter";
import {LicenseManager} from "@ag-grid-enterprise/core";
LicenseManager.setLicenseKey("__[v2]_MTcwMTMwMjQwMDAwMA==3a47625d2e0529ecda3d7731f0725d5f");


const DataScreen = ({ filterChangedCallback }) => {
  const [rowData, setRowData] = useState([]);
  const [country, setCountry] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState([
    {
      TaxYear: "Grand Total:",
      IncomeTotal: 0,
      IncomeTaxable: 0,
      TaxPayable: 0,
      TaxTurnoverPercent: 0,
      TaxTaxablePercent: 0,
    },
  ]);
  const [average, setAverage] = useState({});
  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dropDownSelect, setDropDownSelect] = useState([]);

  const PopupModalRef = useRef();
  // const [pinnedBottomRowData, setPinnedBottomRowData] = useState([
  //   {
  //     column1: "Total",
  //     IncomeTotal: 0,
  //     IncomeTaxable: 0,
  //     TaxPayable: 0,
  //     TaxTaxablePercent: 0,
  //     TaxTurnoverPercent: 0,
  //   },
  // ]);

  const url = process.env.REACT_APP_BUY_BACK_API;

  const handleShow = () => {
    PopupModalRef.current.show();
  };
  const onRowSelected = () => {
    const selectedNodes = gridApi.getSelectedNodes();

    const selectedData = selectedNodes.map((node) => node.data.TaxTransID);
    console.log("Selected Row Data:", selectedData);
    // const refinedData = selectedData.map((item) => {
    //   item.companyName = item.CompanyName;
    //   item.taxCompanyName = item.TaxCompanyName;

    //   return item;
    // });
    console.log("this is refined data");
    setSelectedIds(selectedData);
  };

   //value formatter for the number to 1,0000 instead of 1000
  const formatNumberValue = (value) => {
    // this puts commas into the number eg 1000 goes to 1,000,
    return Math.floor(value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const onGridReady = (params) => {
    const IncomeTotal = rowData.reduce(
      (acc, curr) => acc + curr.IncomeTotal,
      0
    );
    const IncomeTaxable = rowData.reduce(
      (acc, curr) => acc + curr.IncomeTaxable,
      0
    );
    const TaxPayable = rowData.reduce((acc, curr) => acc + curr.TaxPayable, 0);
    const TaxTurnoverPercent = rowData.reduce(
      (acc, curr) => acc + curr.TaxTurnoverPercent,
      0
    );
    const TaxTaxablePercent = rowData.reduce(
      (acc, curr) => acc + curr.TaxTaxablePercent,
      0
    );
    setTotal({
      TaxCompanyName: `Records ${formatNumberValue(rowData.length)}`,
      TaxYear: "Grand Total:",
      IncomeTotal,
      IncomeTaxable,
      TaxPayable,
      TaxTurnoverPercent: (TaxTurnoverPercent / rowData.length).toFixed(3),
      TaxTaxablePercent: (TaxTaxablePercent / rowData.length).toFixed(3),
    });
    setAverage({
      TaxYear: "Average:",
      IncomeTotal: (IncomeTotal / rowData.length).toFixed(3),
      IncomeTaxable: (IncomeTaxable / rowData.length).toFixed(3),
      TaxPayable: (TaxPayable / rowData.length).toFixed(3),
      TaxTurnoverPercent: (TaxTurnoverPercent / rowData.length).toFixed(3),
      TaxTaxablePercent: (TaxTaxablePercent / rowData.length).toFixed(3),
    });
  };

  // const onGridReady = (params) => {
  //   setGridApi(params.api);
  // };
  const columnTotalRenderer = (params) => {
    const field = params.colDef.field;
    let total = 0;
    params.data.forEach(function (rowData) {
      total += rowData[field];
    });
    return <span>{total}</span>;
  };
  /////footer for column
  const sumFooter = (params) => {
    let sum = 0;
    params.data.forEach(function (data) {
      sum += data[params.colDef.field];
    });
    return sum;
  };

  //value formatter for the number to 1,0000 instead of 1000
  const formatNumber = (params) => {
    // this puts commas into the number eg 1000 goes to 1,000,
    return Math.floor(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  ///add percentage  sign to the number  eg 10%
  const percentFormatter = (params) => {
      if(typeof params.value === 'number'){
        return params.value
      }

      if(typeof params.value === 'object'){
        let value;
        if(params.value?.value !== null){
          value = (params.value?.value)?.toFixed(3);
          return value;
        }
      }
    // return params.value;
  };
  ////to pin column totals on every page //////////////////////////////////////////////////////////////////
  const totals = { name: "Total" };
  const PinnedBottomRow = (props) => {
    return (
      <div>
        <div>{props.data.IncomeTotal}</div>
        <div>{props.data.IncomeTaxable}</div>
        {/* <div>{props.data.email}</div> */}
      </div>
    );
  };
  const applyFilter = useCallback(() => {
    filterChangedCallback();
  }, [filterChangedCallback]);
  // //////resizer///
  const FullHeightResizeBar = () => {
    return (
      <div style={{ height: "100%", width: "2px", background: "blue" }}></div>
    );
  };
  ///////group header name //////////////////////////////////////////////////////////////////

  const handleFloatingFilterChanged = (column, value) => {
    gridApi.setQuickFilter(value);
  };
  ///floating filter////////////////////////////////////////////////////////////////

  // ///////////totals////////////////////////////////
  const FooterRenderer = ({ value, column, total }) => {
    return (
      <div style={{ textAlign: "center", fontWeight: "bold" }}>
        {column.colId === "IncomeTotal" ? total.incomeTotal : null}
        {column.colId === "IncomeTaxable" ? total.IncomeTaxable : null}
        {column.colId === "TaxPayable" ? total.TaxPayable : null}
      </div>
    );
  };
  // /////////// function that runs when the rows are selected ///////////
  const handleRowSelected = (event) => {
    setSelectedRows(event.api.getSelectedNodes());
    console.log("was called ");
  };

  const [columnDefs] = useState([
    {
      field: "CompanyName",
      headerName: "Parent Company",
      suppressRowDrag: true,
      headerClass: ["header-border","border-remove"],
      cellClass: "cell-left-aligned",
      applyMiniFilterWhileTyping: true,
      enableRowGrouping: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      filterParams: {
        closeOnApply: true,
      },
      enableRowGroup: true
    },

    {
      field: "AddressCountry",
      headerName: "Country",
      headerClass: ["header-border", "header-border-left","border-remove"],
      // filter: CountryFilter,
      filter: "agSetColumnFilter",
      filterParams: {
        applyMiniFilterWhileTyping: false,
        closeOnApply: true,
      },

      floatingFilter: CountryFilter,

      cellEditor: "agSelectCellEditor",
      cellRenderer: FlagComponent,
      floatingFilterComponentParams: {
        suppressFilterButton: false,
        value: function (params) {
          return params.api.getFilterModel()[params.colDef.field];
        },
      },
      enableRowGroup: true
    },
    {
      field: "TaxCompanyName",
      headerClass: ["header-border","border-remove"],
      cellClass: "cell-left-aligned",
      filter: "agTextColumnFilter",
      closeOnApply: true,
      filterParams: {
        filterOptions: ["contains"],
        closeOnApply: true,
      },
      enableRowGroup: true, 
    },
    {
      field: "CompanyABN",
      headerName: "ABN",
      headerClass: ["header-border","border-remove"],
      filter: "agTextColumnFilter",
      closeOnApply: true,
      cellClass: "cell-right-aligned",
      enableRowGroup: true,
      filterParams: {
        closeOnApply: true,
        filterOptions: ["contains"],
      },
    },
    {
      field: "Industry",
      headerClass: ["header-border","border-remove"],
      cellClass: "cell-left-aligned",
      applyMiniFilterWhileTyping: true,
      filterParams: {
        closeOnApply: true,
      },
      enableRowGroup: true
    },
    {
      field: "TaxYear",
      headerClass: ["header-border","border-remove"],
      filter: "agSetColumnFilter",

      floatingFilter: true,
      // sort: "desc",
      // floatingFilterComponent: FloatingFilter,
      // floatingFilterComponentParams: {
      //   suppressFilterButton: true,
      //   color: "orange",
      // },
      filterParams: {
        comparator: function (a, b) {
          return b.localeCompare(a);
        },
        applyMiniFilterWhileTyping: false,
        sortValues: "desc",
        closeOnApply: true,
      },
      enableRowGroup: true
    },
    {
      headerClass: "header-border",
      headerName: "Income Figures",
      enableRowGrouping:false,
      enableRowGroup: false,
      suppressMovable: true,
      children: [
        {
          field: "IncomeTotal",

          headerClass: "header-border",
          aggFunc: "sum",
          headerName: "Total turnover",
          cellRenderer: "footerRenderer",
          suppressMovable: true,
          enableRowGroup: false,
          // groupIncludeTotalFooter: false,

          filter: "agNumberColumnFilter",
          closeOnApply: true,
          filterParams: {
            applyMiniFilterWhileTyping: true,
            closeOnApply: true,
          },
          // onFloatingFilterChanged: handleFloatingFilterChanged,
          cellClass: "cell-right-aligned",

          cellRendererParams: {
            suppressCount: true,
            headerValueGetter: (params) => params.colDef.headerName,
          },
          valueFormatter: formatNumber,
          footerCellRenderer: columnTotalRenderer,

          footerValueGetter: sumFooter,
          footer: "total",
          suppressAggAtGroup: true,
          suppressAggAtRootLevel: true,
          floatingFilter: true,
          // floatingFilterComponent: FloatingFilter,
          // floatingFilterComponentParams: {
          //   suppressFilterButton: true,
          //   color: "orange",
          // },
        },
        {
          field: "IncomeTaxable",
          aggFunc: "sum",
          headerClass: "header-border",
          headerName: "Net Profit",
          suppressMovable: true,
          type: "numericColumn",
          enableRowGroup: false,
          cellClass: "cell-right-aligned",
          filter: "agNumberColumnFilter",
          floatingFilter: true,
          cellRenderer: "footerRenderer",
          // floatingFilterComponent: FloatingFilter,
          // floatingFilterComponentParams: {
          //   color: "orange",
          //   closeOnApply: true,
          // },

          valueFormatter: formatNumber,
          suppressAggAtGroup: true,
          suppressAggAtRootLevel: true,
        },
      ],
    },
    {
      field: "TaxPayable",
      headerClass: "header-border",
      headerName: "Tax paid",
      aggFunc: "sum",
      type: "number",
      cellClass: "cell-right-aligned",
      suppressMovable: true,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      cellRenderer: "footerRenderer",
      // floatingFilterComponent: FloatingFilter,
      // floatingFilterComponentParams: {
      //   color: "orange",
      // },
      valueFormatter: formatNumber,
      suppressAggAtGroup: true,
      suppressAggAtRootLevel: true,
      enableRowGroup: false,
    },
    {
      headerClass: "header-border",
      headerName: "Tax Paid as a Percentage of",
      enableRowGrouping:false,
      enableRowGroup: false,
      suppressMovable: true,
      children: [
        {
          field: "TaxTurnoverPercent",
          headerName: "Turn over",
          headerClass: "header-border",
          // aggFunc: "sum",
          filter: "agNumberColumnFilter",
          cellClass: "cell-right-aligned",
          suppressMovable: true,
          aggFunc: "avg",
          suppressAggAtGroup: true,
          suppressAggAtRootLevel: true,
          enableRowGroup: false,

          valueFormatter: percentFormatter,
        },
        {
          field: "TaxTaxablePercent",
          headerClass: "header-border",
          headerName: "Tax Rate",
          filter: "agNumberColumnFilter",
          suppressMovable: true,
          enableRowGroup: false,
          aggFunc: "avg",
          cellClass: "cell-right-aligned",
          suppressAggAtGroup: true,
          suppressAggAtRootLevel: true,
          filterParams: { closeOnApply: true },

          valueFormatter: percentFormatter,

          // aggFunc: sumPercentages,

          // valueFormatter: (params) => params.data.number.toFixed(2),
        },
      ],
    },
  ]);
  const rowStyle = { textAlign: "center" };

  const gridOptions = {
    rowGroupPanelShow: "always",
    // pinnedBottomRowData: { pinnedBottomRowData },
    headerBorder: false,
    getRowStyle: function (params) {
      if (!params.node.group) {
        return {
          borderBottom: "none",
        };
      } else {
        return {
          borderBottom: "1px solid black",
        };
      }
    },
    resizeBar: FullHeightResizeBar,
    applyMiniFilterWhileTyping: true,
    rowHeader: "ag-header-row",
    enableValue: true,
    // enableRowGroup: true,
    enableCellChangeFlash: true,
    groupSuppressBlankHeader: true,
    groupRowRenderer: function (params) {
      const total = params.data.reduce((acc, curr) => acc + curr.value, 0);
      return `Group: ${params.key} (Total: ${total})`;
    },

    licenseKey: "MTY3NTkwMDgwMDAwMA==f963b50b55220807b3a817d0934b15b8",
    enableGroup: true,
    groupIncludeFooter: true,
    suppressAggFuncInHeader: true,
    floatingFilterComponentParams: { suppressFilterButton: true },

    // groupRowRenderer: "groupRowRenderer",
  };

  const defaultColDef = useMemo(() => ({
    sortable: true,
    flex: 1,
    // enableRowGroup: true,
    filter: true,
    resizable: true,
    // rowGroup: true,
    floatingFilter: true,
    filterParams: {
      buttons: ["apply", "clear", "cancel", "reset"],
      filterOptions: ["equals", "notEqual", "lessThanOrEqual", "greaterThanOrEqual", "inRange"],
    },
    cellStyle: {
      "border-right-color": "#E4E3E2",
      "border-left-color": "#E4E3E2",
    },
  }));

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 300,
      // cellRendererParams: {
      //   innerRenderer: InnerRender,
      // },
    };
  }, []);

  // ///////context menu pop up ///////////////////////////////////
  const getContextMenuItems = (params) => {
    var result = [
      {
        // custom item
        name: "Reclassify  " + params.value,
        action: () => {
          window.alert("Alerting about " + params.value);
        },
        cssClasses: ["redFont", "bold"],
        disabled: true,
      },
      {
        // custom item
        name: "Update parent",
        disabled: true,

        action: function () {
          // window.alert("the assign button was clicked");
          // setShowModal(true);
          handleShow();
        },
        // disabled: false,
        tooltip: "to assign industry click on this button",
      },

      {
        name: "Custom dropDown list",
        disabled: true,

        subMenu: [
          {
            name: "Nial",
            action: () => {
              console.log("Niall was pressed");
            },
          },
          {
            name: "Sean",

            action: () => {
              console.log("Sean was pressed");
            },
          },
          {
            name: "John",

            action: () => {
              console.log("John was pressed");
            },
          },
          {
            name: "Alberto",
            action: () => {
              console.log("Alberto was pressed");
            },
          },
          {
            name: "Tony",
            action: () => {
              console.log("Tony was pressed");
            },
          },
          {
            name: "Andrew",
            action: () => {
              console.log("Andrew was pressed");
            },
          },
          {
            name: "Kev",
            action: () => {
              console.log("Kev was pressed");
            },
          },
          {
            name: "Will",
            action: () => {
              console.log("Will was pressed");
            },
          },
          {
            name: "Armaan",
            action: () => {
              console.log("Armaan was pressed");
            },
          },
        ],
      },
      "separator",
      {
        // custom item
        name: "Check status",
        disabled: "true",

        action: () => {
          console.log("check Item Selected");
        },
        icon: '<img src="https://www.ag-grid.com/example-assets/skills/windows.png" />',
      },
      {
        // custom item
        name: "Add to industry",
        disabled: true,

        action: () => {
          console.log("Mac Item Selected");
        },
        // icon: '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
      },
      "separator",
      {
        // custom item
        name: "Checked",
        disabled: true,

        checked: true,
        action: () => {
          console.log("Checked Selected");
        },
        icon: '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
      },
      "copy",
      "separator",
      "chartRange",
    ];
    const selectedNodes = params.api.getSelectedNodes();
    if (selectedNodes.length === 0) {
      return [
        {
          // custom item
          name: "Reclassify  " + params.value,
          action: () => {
            window.alert("Alerting about " + params.value);
          },
          cssClasses: ["redFont", "bold"],
          disabled: true,
        },
        {
          // custom item
          name: "Update parent company",
          disabled: true,

          action: function () {
            // window.alert("the assign button was clicked");
            // setShowModal(true);
            handleShow();
            onRowSelected();
          },
          // disabled: false,
          tooltip: "to assign industry click on this button",
        },

        {
          name: "Custom dropDown list",
          disabled: true,

          subMenu: [
            {
              name: "Nial",
              action: () => {
                console.log("Niall was pressed");
              },
            },
            {
              name: "Sean",

              action: () => {
                console.log("Sean was pressed");
              },
            },
            {
              name: "John",

              action: () => {
                console.log("John was pressed");
              },
            },
            {
              name: "Alberto",
              action: () => {
                console.log("Alberto was pressed");
              },
            },
            {
              name: "Tony",
              action: () => {
                console.log("Tony was pressed");
              },
            },
            {
              name: "Andrew",
              action: () => {
                console.log("Andrew was pressed");
              },
            },
            {
              name: "Kev",
              action: () => {
                console.log("Kev was pressed");
              },
            },
            {
              name: "Will",
              action: () => {
                console.log("Will was pressed");
              },
            },
            {
              name: "Armaan",
              action: () => {
                console.log("Armaan was pressed");
              },
            },
          ],
        },
        "separator",
        {
          // custom item
          name: "Check status",
          disabled: "true",

          action: () => {
            console.log("check Item Selected");
          },
          icon: '<img src="https://www.ag-grid.com/example-assets/skills/windows.png" />',
        },
        {
          // custom item
          name: "Add to industry",
          disabled: true,

          action: () => {
            console.log("Mac Item Selected");
          },
          // icon: '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
        },
        "separator",
        {
          // custom item
          name: "Checked",
          disabled: true,

          checked: true,
          action: () => {
            console.log("Checked Selected");
          },
          icon: '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
        },
        "copy",
        "separator",
        "chartRange",
      ];
    }
    return [
      {
        // custom item
        name: "Reclassify  " + params.value,
        action: () => {
          window.alert("Alerting about " + params.value);
        },
        cssClasses: ["redFont", "bold"],
        // disabled: true,
      },
      {
        // custom item
        name: "Update parent company",
        // disabled: true,

        action: function () {
          // window.alert("the assign button was clicked");
          // setShowModal(true);
          handleShow();
          onRowSelected();
        },
        // disabled: false,
        tooltip: "to assign industry click on this button",
      },

      {
        name: "Custom dropDown list",
        // disabled: true,

        subMenu: [
          {
            name: "Niall",
            action: () => {
              console.log("Niall was pressed");
            },
          },
          {
            name: "Sean",

            action: () => {
              console.log("Sean was pressed");
            },
          },
          {
            name: "John",

            action: () => {
              console.log("John was pressed");
            },
          },
          {
            name: "Alberto",
            action: () => {
              console.log("Alberto was pressed");
            },
          },
          {
            name: "Tony",
            action: () => {
              console.log("Tony was pressed");
            },
          },
          {
            name: "Andrew",
            action: () => {
              console.log("Andrew was pressed");
            },
          },
          {
            name: "Kev",
            action: () => {
              console.log("Kev was pressed");
            },
          },
          {
            name: "Will",
            action: () => {
              console.log("Will was pressed");
            },
          },
          {
            name: "Armaan",
            action: () => {
              console.log("Armaan was pressed");
            },
          },
        ],
      },
      "separator",
      {
        // custom item
        name: "Check status",
        // disabled: "true",

        action: () => {
          console.log("check Item Selected");
        },
        icon: '<img src="https://www.ag-grid.com/example-assets/skills/windows.png" />',
      },
      {
        // custom item
        name: "Add to industry",
        // disabled: true,

        action: () => {
          console.log("Mac Item Selected");
        },
        // icon: '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
      },
      "separator",
      {
        // custom item
        name: "Checked",
        // disabled: true,

        checked: true,
        action: () => {
          console.log("Checked Selected");
        },
        icon: '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
      },
      "copy",
      "separator",
      "chartRange",
    ];

    // return result;
  };

  // test functions//////
  const checkdata = () => {
    let selectedRows;
    selectedRows = this.gridApi.getSelectedRows();
    console.log("selected row data " + selectedRows);
  };

  // end of test function///
  useEffect(() => {
    const dropDownList = async () => {
      await axios
        .get(
          "https://api.theproducthub.co/systemselect/listselection?SystemCode=RETAILER&SelectionCode=TYPE"
        )
        .then((response) => {
          let data = JSON.stringify(response.data);
          setDropDownSelect(data);
          console.log("response from the select endpoint " + data);
        });
    };
    const getCountries = async () => {
      await axios
        .get("https://api.theproducthub.co/tt/countries")
        .then((response) => {
          let data = response.data;
          setCountry(data);
          console.log("this for the contries " + data);
        });
    };
    const getData = async () => {
      let canceled = false;
      setLoading(true);

      const res = await axios.get(url).then((response) => {
        let data = response.data;
        let newdata = data.map((item) => {
          item.IncomeTotal = parseInt(item.IncomeTotal, 10);
          item.TaxPayable = parseInt(item.TaxPayable, 10);
          item.IncomeTaxable = parseInt(item.IncomeTaxable, 10);
          item.TaxTurnoverPercent = parseFloat(
            (item.TaxTurnoverPercent * 100).toFixed(3)
          );
          item.TaxTaxablePercent = parseFloat(
            (item.TaxTaxablePercent * 100).toFixed(3)
          );
          return item;
        });

        console.log(newdata);
        setRowData(newdata);
      });

      if (!canceled) {
        setLoading(false);
      }

      let data = res.data;
      console.log(res.data);
      setRowData(data);
    };

    getData();
    getCountries();
    // dropDownList();
    // if (rowData === []) {
    //   calculateTotals();
    // }

    // console.log(setRowData);
  }, []);

  // set total and average after group perform
  const setTotalFunction = (rowData)=>{
    if(rowData.length !== 0){
      const IncomeTotal = rowData.reduce(
        (acc, curr) => acc + curr.aggData?.IncomeTotal,
        0
      );
      const IncomeTaxable = rowData.reduce(
        (acc, curr) => acc + curr.aggData?.IncomeTaxable,
        0
      );
      const TaxPayable = rowData.reduce((acc, curr) => acc + curr.aggData?.TaxPayable, 0);
      const TaxTurnoverPercent = rowData.reduce(
        (acc, curr) => acc + curr.aggData?.TaxTurnoverPercent?.value,
        0
      );
      const TaxTaxablePercent = rowData.reduce(
        (acc, curr) => acc + curr.aggData?.TaxTaxablePercent?.value,
        0
      );
   
      setTotal({
        TaxCompanyName: `Records ${rowData?.length}`,
        TaxYear: "Grand Total:",
        IncomeTotal,
        IncomeTaxable,
        TaxPayable,
        TaxTurnoverPercent: (TaxTurnoverPercent / rowData.length).toFixed(3),
        TaxTaxablePercent: (TaxTaxablePercent / rowData.length).toFixed(3),
      });
      setAverage({
        TaxYear: "Average:",
        IncomeTotal: (IncomeTotal / rowData.length).toFixed(3),
        IncomeTaxable: (IncomeTaxable / rowData.length).toFixed(3),
        TaxPayable: (TaxPayable / rowData.length).toFixed(3),
        TaxTurnoverPercent: (TaxTurnoverPercent / rowData.length).toFixed(3),
        TaxTaxablePercent: (TaxTaxablePercent / rowData.length).toFixed(3),
      });
    }else{
      onGridReady()
    }
  }

  //set total and average after filter perform
  const setTotalFunctionFilter = (rowData)=>{
    if(rowData.length !== 0){
      const IncomeTotal = rowData.reduce(
        (acc, curr) => acc + curr?.data?.IncomeTotal || 0,
        0
      );
      const IncomeTaxable = rowData.reduce(
        (acc, curr) => acc + curr?.data?.IncomeTaxable || 0,
        0
      );
      const TaxPayable = rowData.reduce((acc, curr) => acc + curr?.data?.TaxPayable || 0, 0);
      const TaxTurnoverPercent = rowData.reduce(
        (acc, curr) => acc + curr?.data?.TaxTurnoverPercent || 0,
        0
      );
      const TaxTaxablePercent = rowData.reduce(
        (acc, curr) => acc + curr?.data?.TaxTaxablePercent || 0,
        0
      );
   
      setTotal({
        TaxCompanyName: `Records ${rowData?.length}`,
        TaxYear: "Grand Total:",
        IncomeTotal,
        IncomeTaxable,
        TaxPayable,
        TaxTurnoverPercent: (TaxTurnoverPercent / rowData.length).toFixed(3),
        TaxTaxablePercent: (TaxTaxablePercent / rowData.length).toFixed(3),
      });
      setAverage({
        TaxYear: "Average:",
        IncomeTotal: (IncomeTotal / rowData.length).toFixed(3),
        IncomeTaxable: (IncomeTaxable / rowData.length).toFixed(3),
        TaxPayable: (TaxPayable / rowData.length).toFixed(3),
        TaxTurnoverPercent: (TaxTurnoverPercent / rowData.length).toFixed(3),
        TaxTaxablePercent: (TaxTaxablePercent / rowData.length).toFixed(3),
      });
    }else{
      onGridReady()
    }
  }

  const onRowGroupChanged = (event) => {
    const api = event.api;
    let totalValues = [];
    api.forEachNodeAfterFilterAndSort(function (node) {
      // Check if the node is a group node
      if(node.group){
        totalValues.push(node);
      }
    });
    setTotalFunction(totalValues)
  };

  const onFilterChange = (event)=>{
    const api = event.api;
    let totalValues = [];

    api.forEachNodeAfterFilter(function(node){
      if(node){
        totalValues.push(node)
      }
    })

    setTotalFunctionFilter(totalValues);
  }
  
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="grid-container">
            <div className="ag-theme-alpine" style={{ height: 900 }}>
              <AgGridReact
                rowStyle={rowStyle}
                rowData={rowData}
                rowSelection="multiple"
                popupParent={document.body}
                animateRows={true}
                autoGroupColumnDef={autoGroupColumnDef}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                // pinnedBottomRowData={pinnedBottomRowData}
                pinnedBottomRowData={[total, average]}
                pagination={true}
                // context menu
                getContextMenuItems={getContextMenuItems}
                onSelectionChanged={handleRowSelected}
                // rowSelection={multiple}
                enableValue={true}
                paginationPageSize={10}
                onColumnRowGroupChanged ={onRowGroupChanged}
                gridOptions={gridOptions}
                groupIncludeFooter={true}
                onFilterChanged={onFilterChange}
                groupIncludeTotalFooter={true}
                paginationAutoPageSize={true}
                onGridReady={(params) => {
                  setGridApi(params.api);
                  setColumnApi(params.columnApi);
                  onGridReady();
                }}
                // onGridReady={onGridReady}
              ></AgGridReact>
              <PopupModal
                selectedIds={selectedIds}
                showModal={showModal}
                ref={PopupModalRef}
                handleClose={() => setShowModal(false)}
              />
            </div>
          </div>
          <div className="footer">
            <p>All data sourced from the Australian Tax Office Annual&nbsp;
            <a  href="https://www.ato.gov.au/Business/Large-business/In-detail/Tax-transparency/" target="_blank" rel="noopener noreferrer">Tax Transparency</a>&nbsp;Reports</p>
          </div>
        </>
      )}
    </div>
  );
};
export default DataScreen;
import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from "react";
import "./CountryFilter.css";
import axios from "axios";
export default forwardRef((props, ref) => {
  const [countries, setCountries] = useState([]);
  const [filterState, setFilterState] = useState("");
  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filterState;
      },
      doesFilterPass(params) {
        // console.log(`params: ${params}`);
        const field = props.colDef.field;
        return params.data[field] === filterState;
      },
      getModel() {
        if (filterState == "") {
          return undefined;
        }
        return { state: filterState };
      },
      setModel(model) {
        if (model == null) {
          setFilterState("");
        } else {
          setFilterState(model.state);
        }
      },
      getModelAsString() {
        return filterState === "" ? setFilterState : filterState;
      },
    };
  });
  const clearHandler = () => {
    setFilterState("");
  };
  useEffect(() => {
    const getCountries = async () => {
      await axios
        .get("https://api.theproducthub.co/tt/countries")
        .then((response) => {
          let data = response.data;
          setCountries(data);
        });
    };
    props.filterChangedCallback();
    getCountries();
  }, [filterState]);

  return (
    <>
      <div>
        <p className="title">Filter by country</p>
      </div>
      <select
        className="select"
        value={filterState}
        onChange={(e) => {
          setFilterState(e.target.value);
        }}
      >
        {countries.map((item) => {
          return (
            <option key={item.CountryCodeAN2} value={item.CountryCodeAN2}>
              {item.AddressCountry}
            </option>
          );
        })}
      </select>
      <div className="button_container">
        <button className="button" onClick={clearHandler}>
          clear filter
        </button>
      </div>
    </>
  );
});

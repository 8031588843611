import React, { memo } from "react";
import axios from "axios";

//this is the search function
const searchCompanyEndPoint = async (searchTerm) => {
  return await axios.get(
    "https://api.theproducthub.co/pa/searchParentCompanyByName/" + searchTerm
  );
};

const createNewCompanyEndPoint = async (
  CompanyName,
  CompanyPhone,
  CompanyEmail,
  CompanyWebsite,
  CompanyCountry,
  CompanyIndustry
) => {
  return await axios.post(
    "https://api.theproducthub.co/pa/createParentCompany",
    {
      CompanyName,
      CompanyPhone,
      CompanyEmail,
      CompanyWebsite,
      CompanyCountry,
      CompanyIndustry,
    }
  );
};

const ListIndustriesEndpoint = (async) => {};

export { searchCompanyEndPoint, createNewCompanyEndPoint };

import swal from "sweetalert2";

const FormDisabledError = () => {
  swal.fire({
    title: "You missed a step",
    text: "Search for a parent company  first before proceeding",
    icon: "info",
    showConfirmButton: "true",
    confirmButtonColor: "blue",
  });
};

export { FormDisabledError };

import swal from "sweetalert2";

const SuccessHandler = () => {
  swal.fire({
    title: "Success",
    text: "The parent company has been assigned successfully  ",
    icon: "success",
    confirmButtonColor: "blue",
  });
};

export { SuccessHandler };

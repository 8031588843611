import swal from "sweetalert2";

const FormErrorHandler = () => {
  swal.fire({
    title: "ERROR !",
    text: "There was an error creating the company , please ensure , the `Name` field is not blank ",
    icon: "error",
    confirmButtonColor: "#oD6EFD",
  });
};

export { FormErrorHandler };

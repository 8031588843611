import React, { useState, useEffect } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import ReactSwitch from "react-switch";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import "@inovua/reactdatagrid-enterprise/index.css";
import "@inovua/reactdatagrid-enterprise/base.css";
import "@inovua/reactdatagrid-enterprise/theme/default-dark.css";
import "@inovua/reactdatagrid-enterprise/theme/default-light.css";
import axios from "axios";
import styles from "./Tax.module.css";
import ReactCountryFlag from "react-country-flag";
import Button from "../components/Button"; //import of the button component
import Help from "../components/Help"; // import of the help component
import Media from "react-media";
import ScreenMessage from "../components/ScreenMessage";
import Footer from "../components/Footer";
import { AlignStart } from "../components/StylingProp";
import { AlignEnd } from "../components/StylingProp";
import Loader from "../components/Loader";

const gridStyle = { minHeight: 850 };

const sum = (a, b) => a + b;
const count = (a) => a + 1;

const renderCountryFlag = (value, data, title) => {
  if (value === "NZ") {
    return (
      <ReactCountryFlag
        countryCode={value}
        svg
        cdnUrl={window.location.origin + "/country-flags/"}
        style={{
          width: "2em",
          height: "2em",
          objectFit: "cover",
        }}
        title={title}
      />
    );
  }
  return (
    <ReactCountryFlag
      countryCode={value}
      svg
      style={{
        width: "2em",
        height: "2em",
      }}
      title={title}
    />
  );
};

const Tax = () => {
  const [ModalOpen, setModalOpen] = useState(false);
  const [gridRef, setGridRef] = useState(null);
  const [record, setRecord] = useState([]);
  const [countries, setCountries] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [taxYears, setTaxYears] = useState([]);

  const [defaultGroupBy, setDefaultGroupBy] = useState([]);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [theme, setTheme] = useState("default-light");
  const [searchParams, setSeachParams] = useSearchParams();
  const companyName = searchParams.get("parent");
  const country = searchParams.get("country");
  const TaxCompanyName = searchParams.get("company");
  const ABN = searchParams.get("abn");
  const Industry = searchParams.get("industry");
  const TaxYear = searchParams.get("taxyear");
  const TaxTurnoverPercent = searchParams.get("turnover");
  const TaxTaxablePercent = searchParams.get("taxpercent");
  const IncomeTotal = searchParams.get("incometotal");
  const IncomeTaxable = searchParams.get("profit");
  const TaxPayable = searchParams.get("taxrate");
  const url = process.env.REACT_APP_BUY_BACK_API;
  const scrollProps = Object.assign(
    {},
    ReactDataGrid.defaultProps.scrollProps,
    {
      autoHide: false,
      alwaysShowTrack: true,
    }
  );
  scrollProps.scrollThumbWidth = 30;
  scrollProps.scrollThumbHeight = 100;
  scrollProps.scrollThumbOverWidth = 30;

  const alignStart = {
    textAlign: "start",
  };

  useEffect(() => {
    let isCancelled = false;

    const getData = async () => {
      setLoading(true);
      await axios.get(url).then((response) => {
        setRecord(response.data);
      });
      if (!isCancelled) {
        setLoading(false);
      }
    };
    const getCountries = async () => {
      await axios
        .get("https://api.theproducthub.co/tt/countries")
        .then((response) => {
          let data = response.data;
          setCountries(data);
        });
    };
    const getIndustry = async () => {
      await axios
        .get("https://api.theproducthub.co/tt/industries")
        .then((response) => {
          let data = response.data;
          setIndustries(data);
        });
    };
    const getTaxYear = async () => {
      await axios
        .get("https://api.theproducthub.co/tt/taxyears")
        .then((response) => {
          let data = response.data;
          setTaxYears(data);
        });
    };
    getData();
    getIndustry();
    getTaxYear();
    getCountries();
    return () => {
      isCancelled = true;
    };
  }, []);

  // useEffect(() => {
  //   setSeachParams(location.search.toLowerCase());
  // });

  const currency = (v) => {
    const str = ("" + v)
      .split("")
      .reverse()
      .map((c, i) => {
        if (i && i % 3 === 0) {
          return c + ",";
        }
        return c;
      })
      .reverse()
      .join("");
    return str;
  };

  const resetData = () => {
    if (window.location.href !== "ttc.taxtransparency.info") {
      window.history.go(0);
    }
  };

  const clearPage = () => {
    gridRef.current.clearAllFilters();
  };

  const columns = [
    {
      name: "CompanyName",
      header: "Parent Company",

      defaultWidth: 220,
      defaultFlex: 1.4,
      cellProps: { style: AlignStart },

      textAlign: "center",
      render: ({ data }) =>
        data.Industry == null ? data.CompanyName : data.CompanyName,
    },
    {
      name: "CountryCodeAN2",
      header: "Country",
      defaultWidth: 120,
      defaultFlex: 0.9,
      textAlign: "center",
      filterEditor: SelectFilter,
      filterEditorProps: {
        dataSource: countries.map((country) => {
          return { id: country.CountryCodeAN2, label: country.AddressCountry };
        }),
      },
      render: ({ value, data }) =>
        renderCountryFlag(value, data, data.AddressCountry),
    },

    {
      name: "TaxCompanyName",
      header: "ATO Company Name",
      defaultWidth: 200,
      textAlign: "center",
      cellProps: { style: AlignStart },

      defaultFlex: 1.6,
    },

    {
      name: "CompanyABN",
      header: "ABN",
      textAlign: "center",
      defaultWidth: 120,
      defaultFlex: 1,
      cellProps: { style: AlignStart },
    },
    {
      name: "Industry",
      header: "Industry",
      textAlign: "center",
      defaultWidth: 80,
      defaultFlex: 0.9,
      filterEditor: SelectFilter,
      filterEditorProps: {
        dataSource: industries.map((industry) => {
          return { id: industry.Industry, label: industry.Industry };
        }),
      },
      render: ({ data }) =>
        data.Industry == null ? data.Industry : data.Industry,
    },
    {
      name: "TaxYear",
      header: "Tax Year",
      textAlign: "center",
      cellProps: { style: AlignEnd },
      defaultWidth: 80,
      defaultFlex: 0.75,
      filterEditor: SelectFilter,
      filterEditorProps: {
        dataSource: taxYears.map((taxYear) => {
          return { id: taxYear.TaxYear, label: taxYear.TaxYear };
        }),
      },
      render: ({ data }) =>
        data.TaxYear == null ? data.TaxYear : data.TaxYear,

      groupSummaryReducer: {
        initialValue: 0,
        reducer: count,
      },
    },

    {
      name: "IncomeTotal",
      header: "Total Turnover",
      group: "Income Figures:",
      defaultWidth: 140,
      textAlign: "center",
      cellProps: { style: AlignEnd },

      //filterEditor: NumberFilter,
      defaultFlex: 1.1,
      render: ({ data }) => currency(data.IncomeTotal),
    },
    {
      name: "IncomeTaxable",
      header: "Net Profit   ",
      group: "Income Figures:",
      cellProps: { style: AlignEnd },
      defaultWidth: 140,

      defaultFlex: 1.1,
      //filterEditor: NumberFilter,
      render: ({ data }) =>
        data.IncomeTaxable === "0" ? "0" : currency(data.IncomeTaxable),
    },
    {
      name: "TaxPayable",
      header: "Tax Paid   ",
      defaultWidth: 140,
      cellProps: { style: AlignEnd },
      defaultFlex: 1.1,
      //filterEditor: NumberFilter,
      render: ({ data }) =>
        data.TaxPayable == null ? null : currency(data.TaxPayable),
    },
    {
      name: "TaxTurnoverPercent",
      header: "Turnover",
      group: "Tax as a percentage of:",
      textAlign: "center",
      defaultWidth: 120,
      cellProps: { style: AlignEnd },

      defaultFlex: 1,
      filterEditor: NumberFilter,
      render: ({ data }) =>
        data.TaxTurnoverPercent == null
          ? null
          : parseFloat(data.TaxTurnoverPercent * 100).toFixed(3) + "%",
    },
    {
      name: "TaxTaxablePercent",
      header: "Tax Rate   ",
      group: "Tax as a percentage of:",
      textAlign: "center",
      defaultWidth: 120,
      defaultFlex: 1,
      cellProps: { style: AlignEnd },

      filterEditor: NumberFilter,
      render: ({ data }) =>
        data.TaxTaxablePercent == null
          ? null
          : parseFloat(data.TaxTaxablePercent * 100).toFixed(3) + "%",
    },
  ];

  const groups = [
    {
      name: "Tax as a percentage of:",

      header: "Taxes as a percentage:",
      textAlign: "center",
    },
    {
      name: "Income Figures:",
      textAlign: "center",

      header: "Income Figures:",
    },
  ];

  const filterValue = [
    {
      name: "CompanyName",
      operator: "contains",
      type: "string",
      textAlign: "center",

      value: companyName,
    },
    {
      name: "CountryCodeAN2",
      operator: "eq",
      type: "select",
      textAlign: "center",

      value: country,
    },
    {
      name: "TaxCompanyName",
      operator: "contains",
      type: "string",
      textAlign: "center",

      value: TaxCompanyName,
    },
    {
      name: "Industry",
      operator: "contains",
      type: "string",
      textAlign: "center",

      value: Industry,
    },
    {
      name: "TaxYear",
      operator: "contains",
      type: "string",
      textAlign: "center",

      value: TaxYear,
    },
    {
      name: "CompanyABN",
      type: "string",
      operator: "contains",
      value: ABN,
    },
    {
      name: "TaxTurnoverPercent",
      operator: "eq",
      type: "number",
      value: TaxTurnoverPercent,
    },
    {
      name: "TaxTaxablePercent",
      operator: "eq",
      type: "number",
      value: TaxTaxablePercent,
    },
    {
      name: "IncomeTotal",
      operator: "contains",
      type: "string",
      value: IncomeTotal,
    },
    {
      name: "IncomeTaxable",
      operator: "contains",
      type: "string",
      value: IncomeTaxable,
    },
    {
      name: "TaxPayable",
      operator: "contains",
      type: "string",
      value: TaxPayable,
    },
  ];

  const footerRowsRender = [
    {
      render: {
        CompanyName: ({ summary }) => (
          <div className={styles.record}>
            Records:{" "}
            <b className={styles.recordNumber}>{summary.companyname}</b>
          </div>
        ),
        TaxYear: <div className={styles.textTotal}>Total:</div>,
        IncomeTotal: ({ summary }) => (
          <div className={styles.total}>{summary.incomeTotal}</div>
        ),
        IncomeTaxable: ({ summary }) => (
          <div className={styles.total}>{summary.incomeTaxTotal}</div>
        ),
        TaxPayable: ({ summary }) => (
          <div className={styles.total}>{summary.taxPayTotal}</div>
        ),
        TaxTurnoverPercent: ({ summary }) => (
          <div className={styles.total}>{summary.percentageTurnover}%</div>
        ),
        TaxTaxablePercent: ({ summary }) => (
          <div className={styles.total}>{summary.precentageProfit}%</div>
        ),
      },
    },
    {
      render: {
        TaxYear: <div className={styles.textTotal}>Average:</div>,
        IncomeTotal: ({ summary }) => (
          <div className={styles.total}>{summary.income}</div>
        ),
        IncomeTaxable: ({ summary }) => (
          <div className={styles.total}>{summary.incomeTax}</div>
        ),
        TaxPayable: ({ summary }) => (
          <div className={styles.total}>{summary.taxPay}</div>
        ),
        TaxTurnoverPercent: ({ summary }) => (
          <div className={styles.total}>{summary.precentageTurnover}%</div>
        ),
        TaxTaxablePercent: ({ summary }) => (
          <div className={styles.total}>{summary.precentageProfit}%</div>
        ),
      },
    },
  ];

  const summaryReducer = {
    initialValue: {
      income: 0,
      incomeTax: 0,
      taxPay: 0,
      companyname: 0,
      incomeTotal: 0,
      incomeTaxTotal: 0,
      taxPayTotal: 0,
      precentageTurnover: 0,
      precentageProfit: 0,
    },
    reducer: (accumulator, item) => {
      accumulator.income += parseInt(item.IncomeTotal);
      accumulator.incomeTax += parseInt(item.IncomeTaxable);
      accumulator.taxPay += parseInt(item.TaxPayable);
      accumulator.companyname += parseInt(item.CompanyName);
      return accumulator;
    },
    complete: (accumulator, arr) => {
      accumulator.incomeTotal = Math.round(accumulator.income).toLocaleString();
      accumulator.incomeTaxTotal = Math.round(
        accumulator.incomeTax
      ).toLocaleString();
      accumulator.taxPayTotal = Math.round(accumulator.taxPay).toLocaleString();

      accumulator.precentageTurnover = (
        (accumulator.taxPay / accumulator.income) *
        100
      ).toFixed(3);
      accumulator.precentageProfit = (
        (accumulator.taxPay / accumulator.incomeTax) *
        100
      ).toFixed(3);
      accumulator.income = Math.round(
        accumulator.income / arr.length
      ).toLocaleString();
      accumulator.incomeTax = Math.round(
        accumulator.incomeTax / arr.length
      ).toLocaleString();
      accumulator.taxPay = Math.round(
        accumulator.taxPay / arr.length
      ).toLocaleString();
      accumulator.companyname = Math.round(arr.length).toLocaleString();

      return accumulator;
    },
  };

  const toggleTheme = () => {
    if (theme === "default-light") {
      setTheme("default-dark");
    } else {
      setTheme("default-light");
    }
  };

  return (
    <div className={styles.main}>
      <Media
        queries={{
          small: "(min-width:599px)", //break point for small devices
          medium: "(min-width:821px)",
        }}
      >
        {(matches) => {
          return matches.small && matches.medium ? (
            <div>
              <div>
                {loading ? (
                  <Loader />
                ) : (
                  <div className={styles.content}>
                    <div className={styles.tcontainer}>
                      <p className={styles.title}>
                        {" "}
                        Tax Transparency Information Service
                      </p>

                      {/* Header section button components  */}
                      <Button
                        title="Help"
                        className={styles.button}
                        onClick={() => {
                          setModalOpen(true);
                        }}
                      />

                      {ModalOpen && <Help setModalOpen={setModalOpen} />}
                      <Button title="Clear Filters" onClick={clearPage} />

                      <Button title="Reset" onClick={resetData} />

                      <Button title="Close" />

                      {/* end of Header section buttons  */}

                      <div className={styles.switch}>
                        <label className={styles.label}>
                          {theme === "default-light"
                            ? "Switch to Dark Mode"
                            : "Switch to Light Mode"}
                        </label>
                        <ReactSwitch
                          onChange={toggleTheme}
                          checked={theme === "default-dark"}
                        />
                      </div>
                    </div>
                    <div className={styles.container}>
                      <ReactDataGrid
                        onReady={setGridRef}
                        idProperty="id"
                        loading={loading}
                        groups={groups}
                        style={gridStyle}
                        defaultFilterValue={filterValue}
                        columns={columns}
                        dataSource={record}
                        showZebraRows={true}
                        enableFiltering={true}
                        scrollProps={scrollProps}
                        enableColumnAutosize={true}
                        summaryReducer={summaryReducer}
                        footerRows={footerRowsRender}
                        nativeScroll={false}
                        theme={theme}
                        pagination
                        defaultLimit={15}
                        defaultGroupBy={defaultGroupBy}
                        // pageSizes={5}
                        // rowsPerPageOptions={[2, 10, 20]}
                        licenseKey={process.env.REACT_APP_DATA_GRID_LICENSE_KEY}
                      />
                      <Footer />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <ScreenMessage />
          );
        }}
      </Media>
    </div>
  );
};

export default Tax;

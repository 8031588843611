import React from "react";
import { ColorRing } from "react-loader-spinner";
import styles from "./Loader.module.css";
const Loader = () => {
  return (
    <div className={styles.loader_container}>
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={["#8D949E", "#8D949E", "#8D949E", "#8D949E", "#8D949E"]}
      />
    </div>
  );
};

export default Loader;

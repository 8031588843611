import axios from "axios";
import react from "react";

const ListCountriesEndPoint = async () => {
  return await axios.get(
    "https://api.theproducthub.co/getstarted/listCountries"
  );
};

export { ListCountriesEndPoint };

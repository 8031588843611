import React from "react";
import "./Button.css"; //import the global button styles
import { Link } from "react-router-dom";

const Button = (props) => {
  return (
    <div>
      <button onClick={props.onClick} className="button" Link={props.Link}>
        {props.title}
      </button>
    </div>
  );
};

export default Button;

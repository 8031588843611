import swal from "sweetalert2";

const ConfirmErrorHandler = () => {
  swal.fire({
    title: "ERROR !",
    text: "There was an error assigning the company , please ensure you have checked any of the checkbox ",
    icon: "error",
    confirmButtonColor: "#oD6EFD",
  });
};

export { ConfirmErrorHandler };

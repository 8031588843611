import axios from "axios";

// this is the update tax data endpoint
const UpdateTaxDataEndpoint = async (TaxTransID, FK_ParentCompanyID) => {
  return await axios.post(
    "https://api.theproducthub.co/tt/updatetaxtransparency",
    { TaxTransID, FK_ParentCompanyID }
  );
};

export { UpdateTaxDataEndpoint };

import Tax from "./page/Tax";
import React from "react";
import DataScreen from "./page/DataScreen";
//import Help from './page/Help';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DataScreen />} />
        <Route path="/_home_old" element={<Tax />} />
      </Routes>
    </Router>
  );
}
// <div>
//   <Help />
// </div>

export default App;

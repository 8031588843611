import React from "react";
import styles from "./ScreenMessage.module.css";
import Button from "./Button.js";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Media from "react-media";

///to help implement media queries
const ScreenMessage = () => {
  const [navigate, setNavigate] = useState("");
  let location = useLocation();

  const navigateHandler = () => {
    window.location.href = " http://www.taxtransparency.info ";
    console.log("clicked");
  };
  return (
    <div className={styles.message_container}>
      <div className={styles.container}>
        <h1 className={styles.sorry_text}>
          <span className={styles.mobile_version}>
            Mobile Version Coming Soon
          </span>
          <span className={styles.tablet_version}>
            Tablet Version Coming Soon
          </span>
        </h1>
        <p className={styles.message_paragraph}>
          <span
            className={`${styles.mobile_version} ,${styles.paragraph_Text}`}
          >
            With the Tax Transparency Data, there is too much to show in a
            mobile window.
          </span>
          <span
            className={`${styles.tablet_version} ,${styles.paragraph_Text}`}
          >
            With the Tax Transparency Data, there is too much to show in a
            tablet window.
          </span>
        </p>
        <p className={styles.message_paragraph}>
          For the best experience, this page should be accessed on a desktop or
          laptop computer.
        </p>
        <p className={styles.message_paragraph}>
          <span className={styles.mobile_version}>
            We are working on a mobile version and will release that as soon as
            we can.
          </span>
          <span className={styles.tablet_version}>
            We are working on a tablet version and will release that as soon as
            we can.
          </span>
        </p>

        {/* <button className={styles.button} onClick={navigateHandler}>
        Back to main page
      </button> */}
        <footer onClick={navigateHandler} className={styles.footer}>
          taxtransparency.info
        </footer>
      </div>
    </div>
  );
};

export default ScreenMessage;

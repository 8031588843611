import React from "react";
import styles from "./Footer.module.css";
const Footer = () => {
  return (
    <div className={styles.footer_container}>
      <p className={styles.title2}>
        All data sourced from the Australian Tax Office Annual&nbsp;
        <a
          href="https://www.ato.gov.au/Business/Large-business/In-detail/Tax-transparency/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Tax Transparency
        </a>
        &nbsp;Reports
      </p>
    </div>
  );
};

export default Footer;

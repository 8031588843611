import React from "react";
import ReactCountryFlag from "react-country-flag";
// import countries from "countries-list";
import ISO6391 from "i18n-iso-countries";

const FlagComponent = ({ value, title, data, CountryCodeAN2 }) => {
    // const countryName = countries.getName(value, "en");
    // const countryData = countries[value];
    var countries = require("i18n-iso-countries");
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    const countryCode = countries.getAlpha2Code(
        value !== undefined && value,
        "en"
    );
    if (value === "New Zealand" || countryCode === "NZ") {
        return (
            // <ReactCountryFlag
            //   addressCountry={countryCode}
            //   svg
            //   cdnUrl={window.location.origin + "/country-flags/nz.svg"}
            //   style={{
            //     width: "2em",
            //     height: "2em",
            //     objectFit: "cover",
            //   }}
            //   title={value}
            // />
            <img
                src={window.location.origin + "/country-flags/nz.svg"}
                alt={value}
                style={{
                    width: "2em",
                    height: "2em",
                    objectFit: "cover",
                }}
            />
        );
    }
    return (
        <>
            <ReactCountryFlag
                countryCode={countryCode}
                svg
                style={{
                    width: "2em",
                    height: "2em",
                }}
                title={value}
                name={value}
            />
        </>
    );
};

export default FlagComponent;
